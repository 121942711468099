
import { Options, Vue } from 'vue-class-component'
import clubTournamentMatchListComp from '@/components/Memberportal/ClubTournamentMatch/index.vue'

@Options({
  components: {
    clubTournamentMatchListComp
  }
})
export default class clubTournamentMatchList extends Vue {}
