import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import CommonFunctions from '@/components/Utility/Common'
import { SysTournamentMatchSchedule, SysTournamentMatchSettlement, tournamentMatchScheduleTeamStatusFriendlyType, SysTournamentRules } from '@/types/SysTournament'
import SysClub from '@/types/SysClub'
import SysTeam from '@/types/SysTeam'
import MembersDataService from '@/services/MembersDataService'
import ClubsDataService from '@/services/ClubsDataService'
import SeasonsDataService from '@/services/SeasonsDataService'
import { Tournaments } from '@/services/TournamentsDataService'
import TeamsDataService from '@/services/TeamsDataService'
import Vue3Html2pdf from 'vue3-html2pdf'

type optionsType = { name: string, id: number }

@Options({
  components: {
    Vue3Html2pdf
  }
})
export default class clubTournamentMatchListComp extends Vue {
  $Message: any
  clubData: SysClub = {} as SysClub
  clubRaekkeValue: optionsType = { name: 'Vælg her', id: 0 }
  clubRaekke: optionsType[] = [{ name: 'Vælg her', id: 0 }]
  tournamentSeasonValue: optionsType = { name: 'Vælg her', id: 0 }
  tournamentSeasonOptions: optionsType[] = [{ name: 'Vælg her', id: 0 }]
  teams: SysTeam[] = []
  teamFilterValue: { teamName: string | undefined, teamId: string | undefined } = { teamName: 'Alle hold', teamId: '0' }
  teamFilterOptions: { teamName: string, teamId: number }[] = [{ teamName: 'Alle hold', teamId: 0 }]
  teamExtraParameterString = ''
  clubTournamentMatchList: SysTournamentMatchSchedule[] = []
  selectedMatch = sessionStorage.getItem('SelectedMatch')
  matchResults: SysTournamentMatchSettlement[] = []
  rankingsModal = false
  teamRankingsArray: {teamId: number, teamName: string, points: number, matches: number, won: number, lost: number, tied: number, wonScore: number, lostScore: number, matchNumbers: number[] }[] = []
  clubRaekkeData: any = []

  public retrieveClubInformation () {
    // Changed to use the userId from loacalStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    let tempClub = {} as SysClub
    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        const tempMember = response.data

        if (tempMember.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Noget gik galt' })
          return
        }

        // usrgroup 3 er klubansvarlig
        // if (tempMember[0].user_id.usrgroup !== 3) {
        //   this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })

        //   return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
        // }

        ClubsDataService.get(Number(tempMember[0].klubber_id.id).toString())
          .then((response) => {
            tempClub = response.data
            this.clubData = tempClub
            this.getClubRaekke(Number(tempClub.id))
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public async getClubRaekke (clubId: number) {
    try {
      const clubRaekkeResponse = await ClubsDataService.getClubberRaekke(clubId.toString())
      for (const data of clubRaekkeResponse.data) {
        this.clubRaekkeData.push(data.raekke)
        this.clubRaekke.push({ name: data.raekke.raekke_navn, id: (data.raekke.id ? Number(data.raekke.id) : 0) })
      }
      const selected = this.clubRaekke.length > 1 ? 1 : 0
      this.clubRaekkeValue = { name: this.clubRaekke[selected].name, id: this.clubRaekke[selected].id }
    } catch (error) {
      console.log('Error>>>', error)
    }
  }

  public getSeasonOptionsData () : void{
    SeasonsDataService.getAll()
      .then((response) => {
        const tempSeasons = response.data

        // Fill the season options array of the filter and regular options select.
        for (const season of tempSeasons) {
          this.tournamentSeasonOptions.push({ name: season.saeson_navn, id: (season.id ? Number(season.id) : 0) })
        }
        const newSeason = this.tournamentSeasonOptions.length > 1 ? this.tournamentSeasonOptions.length - 1 : 0
        this.tournamentSeasonValue = { name: this.tournamentSeasonOptions[newSeason].name, id: this.tournamentSeasonOptions[newSeason].id }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  @Watch('teamFilterValue')
  onTeamFilterChange () : void {
    if (!this.teamFilterValue.teamId || this.teamFilterValue.teamId === '0') {
      this.teamExtraParameterString = ''
    } else {
      this.teamExtraParameterString = '&_where[_or][0][kampprogram_hjemmehold.id]=' + this.teamFilterValue.teamId + '&_where[_or][1][kampprogram_udehold.id]=' + this.teamFilterValue.teamId
    }
    this.retriveClubTournamentMatches()
  }

  public retrieveTeams () : void {
    this.teamFilterOptions = []
    if (this.tournamentSeasonValue.id !== 0 && this.clubRaekkeValue.id !== 0) {
      TeamsDataService.getAll('', null, `saeson_id=${this.tournamentSeasonValue.id}&raekke_id=${this.clubRaekkeValue.id}`)
        .then((response) => {
          this.teams = response.data
          for (const item of this.teams) {
            this.teamFilterOptions.push({ teamName: item.hold_holdnavn, teamId: Number(item.id) })
          }
          this.teamFilterOptions.sort((a, b) => a.teamName.localeCompare(b.teamName))
          this.teamFilterOptions.unshift({ teamName: 'Alle hold', teamId: 0 })
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  @Watch('tournamentSeasonValue')
  onTournamentSeasonValueChange () : void {
    this.retriveClubTournamentMatches()
    this.retrieveTeams()
  }

  @Watch('clubRaekkeValue')
  onClubRaekkeValueChange () : void {
    this.retriveClubTournamentMatches()
    this.retrieveTeams()
  }

  public retriveClubTournamentMatches () {
    if (this.tournamentSeasonValue.id !== 0 && this.clubRaekkeValue.id !== 0) {
      let matchScoresParameters = ''
      const param = 'saeson_id=' + this.tournamentSeasonValue.id + '&raekke_id=' + this.clubRaekkeValue.id + this.teamExtraParameterString
      console.log(param)
      Tournaments.TournamentMatchSchedulesDataService.getAll('kampprogram_dato:desc,kampprogram_kampnr:desc', null, param)
        .then((response) => {
          console.log('Response_Data', response.data)
          this.clubTournamentMatchList = response.data
          for (const item of this.clubTournamentMatchList) {
            matchScoresParameters += '&kampprogram_id.id=' + Number(item.id).toString()
          }
          if (matchScoresParameters.length > 0) {
            Tournaments.TournamentMatchSettlementDataService.getAll('', null, matchScoresParameters)
              .then((response) => {
                this.matchResults = response.data
                console.log('matchResults', this.matchResults)
              })
              .catch((err) => {
                console.error(err)
              })
          }
        })
        .catch((err) => {
          console.log('Error>>>', err)
        })
    }
  }

  public danishDataWClock (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 3) + ' kl. ' + CommonFunctions.getHoursAndMinutes(dateString, false)
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public getEnumComment (protestEnum: tournamentMatchScheduleTeamStatusFriendlyType | null) : string {
    let retVal = ''

    if (protestEnum === null) {
      retVal = ''
    } else if (protestEnum === tournamentMatchScheduleTeamStatusFriendlyType.Afbudhjem) {
      retVal = 'Afbud fra hjemmeholdet'
    } else if (protestEnum === tournamentMatchScheduleTeamStatusFriendlyType.Afbudud) {
      retVal = 'Afbud fra udeholdet'
    } else if (protestEnum === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelsehjem) {
      retVal = 'Udeblivelse fra hjemmeholdet'
    } else if (protestEnum === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelseud) {
      retVal = 'Udeblivelse fra udeholdet'
    } else {
      retVal = ''
    }

    return retVal
  }

  public setSelectedMatch (match: any) : void {
    window.sessionStorage.setItem('SelectedMatch', match.id)
  }

  public getScores (matchId: number) : string {
    let retVal = '0 — 0'

    if (this.matchResults.length > 0) {
      const index = this.matchResults.findIndex((el: any) => Number(el.kampprogram_id.id) === Number(matchId))

      if (index !== -1) {
        const matchResult = this.matchResults[index]
        const udScore = (matchResult.kampafvikling_resultat_ude === null ? '0' : matchResult.kampafvikling_resultat_ude.toString())
        const hjScore = (matchResult.kampafvikling_resultat_hjem === null ? '0' : matchResult.kampafvikling_resultat_hjem.toString())
        retVal = new Array(Math.max(hjScore.length, udScore.length) - hjScore.length + 1).join(' ') + hjScore + ' — ' + udScore + new Array(Math.max(hjScore.length, udScore.length) - udScore.length + 1).join(' ')
      }
    }

    return retVal
  }

  get isGetRankingButtonDisabled () : boolean {
    return this.clubTournamentMatchList?.length === 0
  }

  public async getRanking () : Promise<void> {
    this.rankingsModal = true
    this.teamRankingsArray = []
    // const retrieveRulesPromise = Tournaments.TournamentRulesDataService.getAll()
    const retrieveTeamsPromise = TeamsDataService.getAll('', null, `saeson_id=${this.tournamentSeasonValue.id.toString()}&raekke_id=${this.clubRaekkeValue.id}`)
    const retrieveMatchSchedulePromise = Tournaments.TournamentMatchSchedulesDataService.getAll('', null, `saeson_id=${this.tournamentSeasonValue.id.toString()}&raekke_id=${this.clubRaekkeValue.id}`)
    const retriveMedlemsClubPointsPromise = ClubsDataService.getMedlemsClubPoints(`_where[_or][0][klubber_id]=${this.clubData.id}`)
    const seriesString = this.clubRaekkeValue.name
    // await Promise.all([retrieveRulesPromise, retrieveTeamsPromise, retrieveMatchSchedulePromise])
    await Promise.all([retriveMedlemsClubPointsPromise, retrieveTeamsPromise, retrieveMatchSchedulePromise])
      .then(async (response) => {
        let matchScores: SysTournamentMatchSettlement[] = []
        // let rules = response[0].data as SysTournamentRules[]
        let isFinishingMatchSeries = false
        const teams = response[1].data as SysTeam[]
        const matchSchedule = response[2].data as SysTournamentMatchSchedule[]
        const clubPointsData = response[0].data
        if (seriesString.toLowerCase().includes('slutspil')) {
          isFinishingMatchSeries = true
        }

        // Filter the rules to find the correct ones
        // if (seriesString.toLowerCase().includes('damepairs')) {
        //   console.log('[getRanking()] Damepairs rules filtering is used.')
        //   rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('damepairs'))
        // } else if (seriesString.toLowerCase().includes('junior')) {
        //   console.log('[getRanking()] Junior rules filtering is used.')
        //   rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('juniorrækken'))
        // } else if (seriesString.toLowerCase().includes('oldboys 35') || seriesString.toLowerCase().includes('oldboys +35')) {
        //   console.log('[getRanking()] Oldboys 35+ rules filtering is used.')
        //   rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('oldboys 35'))
        // } else if (seriesString.toLowerCase().includes('oldboys 50') || seriesString.toLowerCase().includes('oldboys +50')) {
        //   console.log('[getRanking()] Oldboys 50+ rules filtering is used.')
        //   rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('oldboys 50'))
        //   // } else if (seriesString.toLowerCase().includes('hverdag region') && (parseInt(seriesString) === 3 || parseInt(seriesString) === 4 || parseInt(seriesString) === 5)) {
        // } else if (seriesString.toLowerCase().includes('hverdag region')) {
        //   const posInString = seriesString.toLowerCase().trim().search('hverdag region')
        //   const endPartOfString = seriesString.toLowerCase().trim().substring(posInString + 14).trim()

        //   if (endPartOfString.startsWith('1')) {
        //     console.log('[getRanking()] Hverdagsrække 1 rules filtering is used.')
        //     rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 1')) >= 0))
        //   } else if (endPartOfString.startsWith('2')) {
        //     console.log('[getRanking()] Hverdagsrække 2 rules filtering is used.')
        //     rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 2')) >= 0))
        //   } else if (endPartOfString.startsWith('3')) {
        //     console.log('[getRanking()] Hverdagsrække 3 rules filtering is used.')
        //     rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 3')) >= 0))
        //   } else if (endPartOfString.startsWith('4')) {
        //     console.log('[getRanking()] Hverdagsrække 4 rules filtering is used.')
        //     rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 4')) >= 0))
        //   } else if (endPartOfString.startsWith('5')) {
        //     console.log('[getRanking()] Hverdagsrække 5 rules filtering is used.')
        //     rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 5')) >= 0))
        //   } else {
        //     console.log('[getRanking()] Hovedturnering rules filtering is used (default).')
        //     rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hovedturnering'))
        //   }
        // } else {
        //   console.log('[getRanking()] Hovedturnering rules filtering is used (default).')
        //   rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hovedturnering'))
        // }
        // console.log('[getRanking()] rules = ' + JSON.stringify(rules))

        // Initialize the ranking array
        for (const team of teams) {
          const clubPointsOfTeam = clubPointsData.find((item: any) => item.hold.id === team.id)
          this.teamRankingsArray.push({
            teamId: Number(team.id),
            teamName: team.hold_holdnavn,
            points: clubPointsOfTeam ? clubPointsOfTeam?.club_point : 0,
            // points: (isFinishingMatchSeries && team.hold_startpoint !== undefined && team.hold_startpoint != null ? Number(team.hold_startpoint) : 0),
            matches: 0,
            won: 0,
            lost: 0,
            tied: 0,
            wonScore: 0,
            lostScore: 0,
            matchNumbers: []
          })
        }
        let matchScoreParameter = ''
        for (const item of matchSchedule) {
          matchScoreParameter += '&kampprogram_id.id=' + Number(item.id).toString()
        }
        matchScoreParameter = matchScoreParameter.slice(1)

        try {
          matchScores = (await Tournaments.TournamentMatchSettlementDataService.getAll('', null, matchScoreParameter)).data
        } catch (err) {
          console.log(err)
        }

        // Constructing the rankings, for each match check that there is some results, then assign scores to the home team and guest team
        for (const match of matchSchedule) {
          const homeTeamId = Number(match.kampprogram_hjemmehold.id)
          const guestTeamId = Number(match.kampprogram_udehold.id)
          let homeScore = 0
          let guestScore = 0
          const scoreIndex = matchScores.findIndex(el => Number(el.kampprogram_id.id) === Number(match.id))

          if (scoreIndex !== -1 && match.kampgodkendt_id !== null && match.kampgodkendt_id.kampgodkendt_godkendt) {
            homeScore = matchScores[scoreIndex].kampafvikling_resultat_hjem === null ? 0 : matchScores[scoreIndex].kampafvikling_resultat_hjem
            guestScore = matchScores[scoreIndex].kampafvikling_resultat_ude === null ? 0 : matchScores[scoreIndex].kampafvikling_resultat_ude
            const homeScoreString = homeScore.toString() + '-' + guestScore.toString()
            const guestScoreString = guestScore.toString() + '-' + homeScore.toString()

            const homeIndex = this.teamRankingsArray.findIndex(el => el.teamId === Number(homeTeamId))
            // const rulesNineToZeroIndex = rules.findIndex(el => el.turneringsregler_resultat === '9-0')

            if (homeIndex !== -1) {
              const wonMatch = homeScore > guestScore
              // Based on the homeScoreString of the homeTeam, find how many points should be awarded
              // const rulesIndex = rules.findIndex(el => el.turneringsregler_resultat === homeScoreString)
              console.log('[getRanking()] homeTeamId = ' + Number(homeTeamId).toString())
              console.log('[getRanking()] homeScoreString = ' + homeScoreString)
              // console.log('[getRanking()] adding points = ' + (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point)))
              this.teamRankingsArray[homeIndex].matchNumbers.push(Number(match.id))
              // this.teamRankingsArray[homeIndex].points += (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point))
              this.teamRankingsArray[homeIndex].matches++
              this.teamRankingsArray[homeIndex].won += wonMatch ? 1 : 0
              this.teamRankingsArray[homeIndex].lost += (!wonMatch && (homeScore !== guestScore) ? 1 : 0)
              this.teamRankingsArray[homeIndex].tied += homeScore === guestScore ? 1 : 0
              this.teamRankingsArray[homeIndex].wonScore += homeScore
              this.teamRankingsArray[homeIndex].lostScore += guestScore

              // Handle points reduction for non-appearance situations of the home team in the matches.
              // if (match.kampprogram_afb_udb === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelsehjem) {
              //   if (seriesString.toLowerCase().includes('hverdag region') && match.raekke_id.raekke_antalspillere === 3) {
              //     this.teamRankingsArray[homeIndex].points += -(rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4)
              //     console.log('Match Id ' + Number(match.id) + ' POINTS reduction with ' + (rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4) + ' of home team.')
              //   } else {
              //     this.teamRankingsArray[homeIndex].points += -3
              //   }
              // }

              // If the team has been withdrawn from the tournament season, then set the value of points to zero.
              // if (match.kampprogram_hjemmehold.hold_status !== null && match.kampprogram_hjemmehold.hold_status === false) {
              //   this.teamRankingsArray[homeIndex].points = 0
              // }
            }

            const guestIndex = this.teamRankingsArray.findIndex(el => el.teamId === Number(guestTeamId))

            if (guestIndex !== -1) {
              const wonMatch = guestScore > homeScore
              // Same for the guestTeam
              // const rulesIndex = rules.findIndex(el => el.turneringsregler_resultat === guestScoreString)
              console.log('[getRanking()] guestTeamId = ' + Number(guestTeamId).toString())
              console.log('[getRanking()] guestScoreString = ' + guestScoreString)
              // console.log('[getRanking()] adding points = ' + (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point)))
              this.teamRankingsArray[guestIndex].matchNumbers.push(Number(match.id))
              // this.teamRankingsArray[guestIndex].points += (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point))
              this.teamRankingsArray[guestIndex].matches++
              this.teamRankingsArray[guestIndex].won += wonMatch ? 1 : 0
              this.teamRankingsArray[guestIndex].lost += (!wonMatch && (homeScore !== guestScore) ? 1 : 0)
              this.teamRankingsArray[guestIndex].tied += guestScore === homeScore ? 1 : 0
              this.teamRankingsArray[guestIndex].wonScore += guestScore
              this.teamRankingsArray[guestIndex].lostScore += homeScore

              // Handle points reduction for non-appearance situations of the away team in the matches.
              // if (match.kampprogram_afb_udb === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelseud) {
              //   if (seriesString.toLowerCase().includes('hverdag region') && match.raekke_id.raekke_antalspillere === 3) {
              //     this.teamRankingsArray[homeIndex].points += -(rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4)
              //     console.log('Match Id ' + Number(match.id) + ' POINTS reduction with ' + (rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4) + ' of away team.')
              //   } else {
              //     this.teamRankingsArray[guestIndex].points += -3
              //   }
              // }

              // If the team has been withdrawn from the tournament season, then set the value of points to zero.
              // if (match.kampprogram_udehold.hold_status !== null && match.kampprogram_udehold.hold_status === false) {
              //   this.teamRankingsArray[guestIndex].points = 0
              // }
            }
          }
        }

        // After the ranking array have been constructed, sort it
        this.teamRankingsArray.sort(function compare (a, b) {
          if (a.points === b.points && (a.matchNumbers.length > 0 || b.matchNumbers.length > 0)) {
            const mutualMatchesNumbers = a.matchNumbers.filter(el => b.matchNumbers.includes(el))
            let teamAWon = 0
            let teamBWon = 0
            let teamAScore = 0
            let teamBScore = 0

            if (mutualMatchesNumbers.length > 0) {
              const teamAId = a.teamId
              // const teamBId = b.teamId
              const mutualMatches = matchScores.filter((el) => {
                return mutualMatchesNumbers.some((f) => {
                  return f === Number(el.kampprogram_id.id)
                })
              })
              for (const match of mutualMatches) {
                // Situation where A is the home team
                if (match.kampprogram_id.kampprogram_hjemmehold === teamAId) {
                  teamAScore += (match.kampafvikling_resultat_hjem - match.kampafvikling_resultat_ude)
                  teamBScore += (match.kampafvikling_resultat_ude - match.kampafvikling_resultat_hjem)

                  if (match.kampafvikling_resultat_hjem > match.kampafvikling_resultat_ude) {
                    teamAWon++
                  }
                  if (match.kampafvikling_resultat_hjem < match.kampafvikling_resultat_ude) {
                    teamBWon++
                  }
                } else {
                  teamAScore += (match.kampafvikling_resultat_ude - match.kampafvikling_resultat_hjem)
                  teamBScore += (match.kampafvikling_resultat_hjem - match.kampafvikling_resultat_ude)

                  if (match.kampafvikling_resultat_ude > match.kampafvikling_resultat_hjem) {
                    teamAWon++
                  }
                  if (match.kampafvikling_resultat_ude < match.kampafvikling_resultat_hjem) {
                    teamBWon++
                  }
                }
              }
              console.log(teamAId + ' Won: ' + teamAWon)
              console.log(teamBWon + ' Won: ' + teamBWon)
              if (teamAWon === teamBWon) {
                if (teamAScore === teamBScore) {
                  const aWonLostRatio = a.wonScore - a.lostScore
                  const bWonLostRatio = b.wonScore - b.lostScore

                  return bWonLostRatio - aWonLostRatio
                }
                return teamBScore - teamAScore
              }
              return teamBWon - teamAWon
            }
          }
          return b.points - a.points
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public downloadPdf () : void {
    const pdfApi = this.$refs.html2Pdf as any
    pdfApi.generatePdf()
  }

  async mounted (): Promise<void> {
    this.retrieveClubInformation()
    this.getSeasonOptionsData()
  }
}
